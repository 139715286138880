<script lang="ts" setup>
import { computed, ref } from "vue";
import ArrowDownLine from "vue-remix-icons/icons/ri-arrow-down-s-line.vue";

import DropdownMenu from "./DropdownMenu.vue";
interface Props {
  /** 메뉴가 나오는 방향 */
  direction?: "auto" | "down" | "up";
  /** 초기 표시 라벨 */
  label?: string;
  /** 드롭다운 높이 초과시 overflow */
  maxHeight?: string;
  /** 메뉴 위치 */
  position?: "left" | "right";
}

const props = withDefaults(defineProps<Props>(), {
  direction: "auto",
  label: undefined,
  maxHeight: "300px",
  position: "left",
});
const triggerRef = ref<HTMLDivElement>();

const direction = ref(props.direction);
const isActive = ref(false);

const calcDirection = computed(
  (): Extract<Props["direction"], "down" | "up"> => {
    const bodyHeight = document.body.getBoundingClientRect().height;
    const elementPoint = triggerRef.value!.getBoundingClientRect();
    return bodyHeight / 2 > elementPoint.y ? "down" : "up";
  },
);

const hover = () => {
  isActive.value = true;
  if (props.direction === "auto") {
    direction.value = calcDirection.value;
  }
};
</script>
<template>
  <div
    @mouseleave="isActive = false"
    @mouseover="hover"
    class="dropdown"
    data-testid="dropdown"
  >
    <div ref="triggerRef">
      <slot name="trigger">
        <div class="dropdown-trigger">
          <span>{{ props.label }}</span>
          <ArrowDownLine class="dropdown-trigger-icon" />
        </div>
      </slot>
    </div>
    <DropdownMenu
      :class="{
        'direction--up': direction === 'up',
        'position--right': props.position === 'right',
      }"
      @click="isActive = false"
      class="dropdown-menu"
      v-show="isActive"
    >
      <slot />
    </DropdownMenu>
  </div>
</template>

<style lang="scss" scoped>
.dropdown {
  position: relative;
}
.dropdown-menu {
  max-height: v-bind("props.maxHeight");
}
.dropdown-trigger {
  color: #212427;
  cursor: pointer;
  padding: 16px 16px 16px 24px;
  border-radius: 8px;
  box-shadow: inset 0 0 0 1px rgba(0, 27, 55, 0.1);
  transition-duration: 0.4s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    box-shadow: inset 0 0 0 2px #007aff;
  }
  .dropdown-trigger-icon {
    width: 24px;
    margin-left: 16px;
    fill: #b3b3b3;
  }
}
.position--right {
  right: 0px;
}
.direction--up {
  bottom: 100%;
}
</style>
